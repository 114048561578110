import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/style.scss";
import "./assets/css/my.css";
import "./assets/css/home2.css";
import "./assets/css/home3.css";
import "./assets/css/home4.css";

import reportWebVitals from "./reportWebVitals";

import Index from "./pages/index.js";
import IndexTwo from "./pages/index-two.js";
import IndexThree from "./pages/index-three.js";
import IndexFour from "./pages/index-four.js";
import IndexFive from "./pages/index-five.js";
import IndexSix from "./pages/index-six.js";
import Login from "./pages/auth/login.js";
import Signup from "./pages/auth/signup.js";
import ResetPassword from "./pages/auth/reset-password.js";
import Home from "./pages/Home.js";
import Privacypolicy from "./pages/privacy-policy.js";
import Termsconditions from "./pages/terms-conditions.js"
import Contact from "./pages/contact.js";
import Home2 from "./pages/Home2.js";
import Home3 from "./pages/Home3.js";
import Contactus from "./pages/contactus.js"
import Home4 from "./pages/Home4.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home5 from "./pages/Home5.js";
import Home6 from "./pages/Home6.js";
import Home7 from "./pages/Home7.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/contactus" element={<Contactus />} />
      <Route path="/Home4" element={<Home4 />} />
      <Route path="/Home5" element={<Home5 />} />
      <Route path="/Home7" element={<Home7 />} />
      <Route path="/" element={<Home6 />} />
      <Route path="/Home3" element={<Home3 />} />
      <Route path="/Home2" element={<Home2 />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/terms-conditions" element={<Termsconditions />} />
      <Route path="/privacy-policy" element={<Privacypolicy />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Index" element={<Index />} />
      <Route path="/index-two" element={<IndexTwo />} />
      <Route path="/index-three" element={<IndexThree />} />
      <Route path="/index-four" element={<IndexFour />} />
      <Route path="/index-five" element={<IndexFive />} />
      <Route path="/index-six" element={<IndexSix />} />
      <Route path="/auth-login" element={<Login />} />
      <Route path="/auth-signup" element={<Signup />} />
      <Route path="/auth-reset-password" element={<ResetPassword />} />

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
