import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Termsconditions = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                            <a href="/">
                           <h3 className="mynewlogo">Repair Auto Collision</h3>
                            </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:+1-888-724-0790">+1-888-724-0790</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Terms & Conditions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h4>Terms of Use</h4>
                            <p>Please read these terms of use carefully before you start to use the website. By using our website, you agree to accept these terms of use and consent to abide by them. If you do not agree to these terms of use, please stop using our website.</p>
                            <h4>Access to website </h4>
                            <p>You are granted a limited, non-exclusive, non-transferable, revocable licence to access and use our website strictly in accordance with these terms of use. You agree to use the website only for lawful purposes and in a manner that does not infringe upon the rights of others, restrict or inhibit anyone else's use or enjoyment of the website, or violate any applicable laws or regulations.</p>
                            <p>If the website requires you to create an account, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorised access to or use of your account.</p>
                            <p>By using our website, you agree not to:</p>
                            <ul>
                                <li>Use the website in any way that could disable, overburden, damage, or impair the site or interfere with any other party's use of the website.</li>
                                <li>Attempt to gain unauthorised access to any part of the website, server, or network connected to the website, or any systems or networks associated with the website.</li>
                                <li>Use any robot, spider, scraper, or other automated means to access the website for any purpose without our express written permission.</li>
                                <li>Use the website to transmit or distribute any viruses, malware, or other harmful computer code.</li>
                            </ul>
                            <h4>Intellectual property rights </h4>
                            <p>All content and materials available on this website, including text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software are our exclusive property. These materials are protected by copyright laws and international treaties.</p>
                            <p>You are granted a limited, revocable, non-exclusive, non-transferable licence to access and use the content and materials on this website strictly for personal, non-commercial purposes. This licence does not permit any resale or commercial use of the website's content, any collection or use of product listings, descriptions, or prices, any derivative use of the website's content, or any use of data mining, robots, or similar data gathering and extraction tools without express written consent. Any unauthorised use of the website's content or materials may violate copyright, trademark, and other laws and result in legal action against you. </p>
                            <h4>Disclaimer </h4>
                            <p>The information provided on this website is for general informational purposes only. While we strive to keep the information accurate and up to date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk.</p>
                            <p>We disclaim all liability for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website. We also do not warrant that the website will be uninterrupted, secure, or error-free, or that defects will be corrected.</p>
                            <h4>Links from other websites </h4>
                            <p>Our website may contain links from third-party websites. We are not responsible for the content, privacy policies or practices of these third-party websites. The inclusion of website links from third parties doesn't mean we endorse these websites. You’re advised to read the privacy policies of such websites before providing them with your personal information. Any reliance you place on the information on these websites is at your own risk. </p>
                            <h4>Limitation of liability </h4>
                            <p>To the maximum extent permitted by applicable law, we and our affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses arising out of or in connection with:</p>
                            <ul>
                                <li>The use or inability to use the website</li>
                                <li>Any content or materials on the website</li>
                                <li>Unauthorised access to or alteration of your transmissions or data</li>
                                <li>Statements or conduct of any third party on the website</li>
                                <li>Any other matter relating to the website.</li>
                            </ul>
                            <h4>Force majeure</h4>
                            <p>We shall not be liable for any failure or delay in performing its obligations under these terms of use if such failure or delay is due to circumstances beyond our reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, strikes, labour disputes, civil unrest, government actions, power failures, and Internet disruptions.
                                In the event of a force majeure event, we will make reasonable efforts to resume normal operations as soon as practicable. During the continuance of such an event, our obligations under these terms may be suspended, and we shall have an extension of time for performance equal to the duration of the force majeure event.
                            </p>
                            <h4>Waiver </h4>
                            <p>Failure of either party to insist upon strict performance of any provision of this or any agreement or the failure of either party to exercise any right or remedy to which it, he or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any agreement. No waiver of any of the provisions of this or any agreement shall be effective unless it is expressly stated to be such and signed by both parties.</p>
                            <h4>Governing law </h4>
                            <p>These terms of use shall be governed and construed in accordance with the English laws. Any legal action or proceeding related to these terms of use shall be brought exclusively in the courts of England and Wales. </p>
                            <h4>Changes to the terms of use </h4>
                            <p>In our sole discretion, we reserve the right to amend these terms of use from time to time. You’re advised to visit this page on a regular basis to stay informed about the changes in our terms of use. </p>
                            <h4>Contact us </h4>
                            <p>If you have any questions or queries about our terms of use, please feel free to contact us at <a href="mailto:info@repair-auto-collision.com">info@repair-auto-collision.com </a>. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <ul>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <p className="home2-colpyy">
                                © Copyright 2024 <a href="/">Repair Auto Collision</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Termsconditions;
