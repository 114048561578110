import React from "react";
import placeholderImage from '../assets/images/123.jpg';
import '../assets/css/Home7.css'
import call from "../assets/images/call.png";


function Home7() {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <h3 className="mynewlogo">Insurance Services UK</h3>
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p><a href="tel:0800 000 0000">0800 000 0000</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>


<section>
     <div className="container">
<div className="row">
<div className="col-xxl-12 col-xl-12 col-md-12 col-12 ">
<div className="boxs">

<h1 className="content2">Road accident claims management</h1>

</div>
</div>
</div>
     </div>
      </section>



      {/* <section className="content1">
        <div className="container">

          <div className="row">
            <div className="col-xxl-12 col-xl-12  content5" >
              <div className="box">
                <h1 className="content2">Road accident claims management</h1>
                <p className="line">Make a successful claim with the guidance of our UK-based no-fault claim experts.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <img src={placeholderImage} className="girlcars" alt="" />
            </div>

            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">


              <div className="groups">
                <h2>Connect with our expert team for guidance.</h2>
                <div className="button-group">
                  <h5>Start Your Claim Today</h5>
                  <a href="tel:+18007563256" className="time-button ">Call +1 800 756 3256</a>
                  <h5 className="mt-5">Get Roadside Assistance</h5>
                  <a href="tel:+18007563256" className="time-button ">Call +1 800 756 3256</a>
                </div>
              </div>
            </div>



          </div>
        </div>
      </section> */}




      {/* <section className="home2-copy-right">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                <li><a href="/contactust">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <p className="home2-colpyy">
                © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>

  )
}


export default Home7;
