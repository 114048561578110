import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";
import girlcar from "../assets/images/girlcar.png";
import arrow from "../assets/images/arrow.png";
import call2 from "../assets/images/call2.png";

const Home3 = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xxl-7 col-xl-7 col-md-6 col-12"> */}
                            <div className="mylogo">
                                <a href="/">
                                    <img src={mainlogo} className="mainlogo" alt="logo" />
                                </a>
                            {/* </div> */}
                        </div>
                        {/* <div className="col-xxl-5 col-xl-5 col-md-6 col-12"> */}
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="home3-main-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="content-box">
                                <h1>Road accident claims management </h1>
                                <p className="accident">Make a successful claim with the guidance of our UK-based no-fault claim experts. </p>
                                <img src={girlcar} className="girlcar" alt="" />
                                <div className="arrow02">
                                    <img src={arrow} className="arrow" alt="" />
                                </div>
                                <h3 className="hr-lines">* 24/7 Dedicated Support</h3>
                                <ul className="third-row">
                                    <li>
                                    <div className="home3-cta1">
                                    <h3 className="Get">Start Your Claim Today</h3><br />
                                            <a href="tel:+1 (800) 756-32-56"><span> <img src={call2} className="cal03" alt="" />
                                            </span>+1 (800) 756-32-56</a>
                                        </div>
                                    </li>
                                    <li>
                                    <div className="home3-cta">
                                    <h3 className="Get">Get Roadside Assistance</h3><br />
                                            <a href="tel:+1 (800) 756-32-56"><span> <img src={call2} className="cal03" alt="" />
                                            </span>+1 (800) 756-32-56</a>
                                        </div>
                                    </li>
                                    
                                </ul>
                            
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
    </>
    );
};

export default Home3;
