import React from "react";
import placeholderImage from '../assets/images/111.jpg';
import '../assets/css/home5.css'
import call2 from "../assets/images/call.png";
import call from "../assets/images/call.png";


function Home5() {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <h3 className="mynewlogo">Repair Auto Collision</h3>
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p><a href="tel:+1-888-724-0790">+1-888-724-0790</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="hero-section">
        <div className="container">
          <h2>Collision Repair Made Easy</h2>
          <p>We provide fast, efficient repair services to get you back on the road safely.</p>
          {/* <div className="steps">
            <div className="step"><span></span> Upload Photos</div>
            <div className="step"><span></span> Schedule Repairs</div>
            <div className="step"><span></span> View Status</div>
          </div> */}
        </div>
      </section>


        <section className="cta-section  ">
        <div className="container row justify-content-center py-5">
          <div className="cta-card col-md-6 col-lg-4 mb-4">
            <h2>Request a Repair</h2>
            <p>Get in touch with our experts to discuss your repair needs.</p>
            <a href="tel:+1-888-724-0790" className="cta-button1 ">Call +1-888-724-0790</a>
          </div>

          <div className="cta-card col-md-6 col-lg-4 mb-4">
            <h2>Immediate Assistance</h2>
            <p>Need urgent help after an accident? Call us for 24/7 support.</p>
            <a href="tel:+1-888-573-3521" className="cta-button ">Call +1-888-573-3521</a>
          </div>
          </div>
        </section>
  

      <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Repair Auto Collision</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
    </>

  )
}


export default Home5;
