import React from "react";
import placeholderImage from '../assets/images/123.jpg';
import '../assets/css/Home6.css'
import call from "../assets/images/call.png";


function Home6() {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <h3 className="mynewlogo">Repair Auto Collision</h3>
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p><a href="tel:+1-888-724-0790">+1-888-724-0790</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="content1">
        <div className="container">

          <div className="row">
            <div className="col-xxl-12 col-xl-12  content5" >
              <div className="box">
                <h1 className="content2">Collision Repair Made Easy</h1>
                <p className="line">We provide fast, efficient repair services to get you back on the road safely.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <img src={placeholderImage} className="girlcars" alt="" />
            </div>

            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              {/*<div className="content-right">
                <div class="content3">
                  <a href="tel:+18007563256" className="cts-button1 ">Call +1 800 756 3256</a> */}

              <div className="groups">
                <h2>Request a Repair</h2>
                <div className="button-group">
                  <h5>Get in touch with our experts to discuss your repair needs.</h5>
                  <a href="+1-888-724-0790" className="time-button ">Call +1-888-724-0790</a>
                  <h5 className="mt-5">Need urgent help after an accident? Call us for 24/7 support.</h5>
                  <a href="tel:+1-888-573-3521" className="time-button ">Call +1-888-573-3521</a>
                </div>
              </div>
            </div>

            {/* </div>
              </div>
            </div> */}


          </div>
        </div>
        
      </section>




      <section className="home2-copy-right">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                <li><a href="/Contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <p className="home2-colpyy">
                © Copyright 2024 <a href="/">Repair Auto Collision</a>. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}


export default Home6;
